/**
 * List types of all gifts that exist on 17Live currently.
 * Refer to backend design
 * @see https://github.com/17media/api/blob/master/models/gift/gift.pb.go#L445
 * @see https://17media.atlassian.net/wiki/spaces/InStream/pages/812777489/Spike+Non-realtime+gift#Gift-model
 */
export enum GiftType {
  Normal = 0,
  LuckyBag = 1,
  Slot = 2,
  Dummy = 3,
  Poke = 4,
  Poll = 5,
  MusicProducer = 6,
  Texture = 7,
  Army = 8,
  Elite = 9,
  Goods = 10,
  LevelUp = 11,
  HandDrawSticker = 12,
  Game = 13,
  Layout = 14,
  TextOnly = 15,
  PM = 16,
  NonRealtime = 17,
  Limited = 18,
  LimitedLuckyBag = 20,
}
